.nav{
    background: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-align: center;
}
.bg-nav {
    background: rgba(0, 0, 0, 0.6);
}
.page_portion{
    display: flex;
    gap: 20px;
    text-align: center;
    color: #00b3b3;
}
.logo{
    width:190px ;
    height: 130px;
    padding: 0 7px;
}
.logo_image{
    width: 100%;
    height: 100%;
    object-fit:contain ;
    
}
.nav_content_css{
    list-style: none;
    text-decoration: none;
    color: white;
    transition: background-color 0.3s;
    padding: 0 20px;
    text-shadow: 0 1px 5px #292929;
}
.nav_content_css:hover {
    color: #79ffe2;
    border-radius: 10px;
}

.menu {
    display: none;
    z-index: 12;
}
.link_heading{
    font-size: 22px;
}

  .active{
    color: #79ffe2;
  }
@media (max-width: 770px) {
    .menu {
        display: block;
    }

    .page_portion {
        flex-direction: column;
        align-items: start;
        display: none;
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        width: -webkit-fill-available;
        padding: 20px;
    }

    .page_portion.show {
        display: flex;
        z-index: 10;
    }
}