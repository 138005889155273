.service_card {
  max-width: 350px !important;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
}

.service_card:hover {
  background-color: #79ffe2;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
}


.lcon_service {
  width: 100%;
  height: 200px;
  margin: auto;
}

.icon_class {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading_serv {
  font-size: 36px;
  font-weight: 700;
}

.readmo {
  margin-top: auto;
}

.readmore {
  width: 100%;
  margin-top: auto !important;
  align-self: center;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.readmore:hover {
  background-color: #0056b3;
}

.service_heading {
  margin-bottom: 0;
  color: #000;
}

.service_paragraph {
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding-inline: 7px;
  color: #000;
}

.icon_class {
  height: 250px;
}