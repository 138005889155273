.client_card{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    width: 70%;
    margin-inline: auto;
    background-color: rgba(121, 255, 226, 0.9);
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    min-height: 160px;
    margin-top: 15px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    gap: 20px;
}
.client_image{
    width: 150px;
    height: 100px;
}
.client_logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
}