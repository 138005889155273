
/* bannner_section */
/* icon fixed css */
.socialMedia_portion{
  position: fixed;
  right: 0;
  top: 30%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,0.6);
  padding: 15px 15px;
  box-shadow: 2px 3px 5px gray;
  cursor: pointer;
  border-radius:  8px 0  0 8px ;
  z-index: 11;
}
.social_icon{
  margin: 10px 0;
  font-size: 30px;
}
.social_icon:hover{
  transform: scale(1.5);
  transition: 0.4s;
  color: #ff6b81 !important;
}


/* rest css */
.video_class{
        position:relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 45em;
        object-fit: cover;
    }
    .content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -380px;
    }
    .text-box {
      
        position: absolute;
        z-index: 1;
        text-align: center;
        color:#11500b;
        padding: 80px;
        background-color: rgba(121, 255, 226, 0.6);
        /* text-shadow: 1px 1px 3px #000000; */
        border-radius: 10px;
    }

    .text-box .tagline {
      font-weight: 600;
      font-size: 1.3rem;
    }

    .card_portion{
        padding: 20px;
    }

    .flex_card_portion{
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(3,1fr);
        align-items: center;
        margin-bottom: 50px;
        flex-wrap: wrap;
        padding: 0px 20px 0px 20px;
        gap: 10px;
    }

    /* .testimonial_card_section{
        margin-inline: auto;
        width: 80%;
        display: flex;
        margin-top: 15px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 30px;
        flex-wrap: wrap;
    } */

    /* Testimonials */

    .testimonial-portion {
      min-height: 100vh;
      margin: 0 0 30px 0;
    }

    .testimonial-portion h1, h3 {
      text-align: center;
    }
    
    .cards-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 20px 0 0 0;
    }
    
    .card {
      opacity: 0;
      transform: translateX(-50px);
      transition: opacity 0.5s, transform 0.5s;
    }
    
    .card.active {
      opacity: 1;
      transform: translateX(0);
    }


       
    .paragraph_serv{
        font-size: 20px;
        font-weight: 400;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .introduction_section{
        padding: 25px;
        background-color: #d9d9d9;
        margin-top: -5px;
    }
    .heading_intro{
        font-size: 50px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 65px;

    }
    .para_intro{
        text-align: left;
        margin: auto;
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 50px;

    }
.Client_portion{
    padding-block: 30px;
}
.bg-color{
  /* background-image: url("https://www.kpsol.com/wp-content/uploads/2015/10/call-center-image.jpg"); */
  /* padding-top: 20px; */
  padding-bottom: 20px;
  background: linear-gradient(90deg, #8ebca6, transparent);
}

.servicesImages {
  width: 100%;
  height: 500px;
}

.servicesImages img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Contact us css */

.contact_page{
    padding: 50px;
    color: black;
    text-align: left;
}

.form_portion{
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    margin-inline: auto;
}

.contact_form{
    display: flex;
    align-self: end;
}


  .cardcontact{
    background-color: white;
    box-shadow: 0 1px 15px rgba(235, 230, 230, 8.0);
    padding: 35px;
    border-radius: 5px;
    width: 500px;
  }
  .head{
    font-size: 20px;
    font-weight: 600;
    margin: 10px 10px 5px 5px ;
  }
  .input{
    background-color: white;
    border: 2px solid black;
    /* box-shadow: 0 1px 15px rgba(0, 0, 0, 7.1); */
    color: #000;
    font-family: inherit;
    padding: 10px 15px;
    width: 93%;
    /* border: none; */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .link_email{
    text-decoration: none;
    
  }
  
  
  .btn{
    width: 100%;
    padding: 10px;
    margin-top: 25px;
    background-color:#79ffe2;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    border-radius: 25px;
  }

  /* About */

  .text_portion {
    padding: 0 24px;
  }

  .about-btn {
    background-color: #79ffe2;
    color: #fff;
    font-size: inherit;
    font-family: inherit;
  }
  .about-btn:hover {
    background-color: #128e82;
  }

  .about_subheading {
    text-align: left !important;
  }
  .about_heading{
    font-size: 60px;
    font-weight: 600;
  }
  .about_paragraph{
    font-size: 45px;
    font-weight: 500;
  }
  .about_paragraph_sub{
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
  }
  .about_paragraph_sub_right{
    text-align: right;
    font-size: 20px;
    font-weight: 400;
  }
  .about_paragraph_sub_left{
    text-align: left;
    font-size: 20px;
    font-weight: 400;
  }
  .image_portion{
    width: 100%;
    height: 100%;
  }
  .image_emporing{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .Empowering_portion{
    display: grid;
    grid-template-columns: repeat(2,1fr); 
    padding-inline: 40px;
    gap: 10px;
    margin-bottom: 10%;


  }
  .sub_head{
    font-size: 40px;
    font-weight: 400;
  }
  .sub_head_2{
    font-size: 35px;
    font-weight: 300;
    align-items: center;
  }
  .card_grid_p{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
    flex-wrap: wrap;
  }
  .about_paragraph_Team{
    font-size: 20px;
    font-weight: 300;
    width: 80%;
    margin-inline: auto;

  }
  .border-boxing{
    text-align: left;
    width: 80%;
    margin: auto;
  }
  .privacy_list {
    padding-inline: 25px;
    list-style: disc;
    color: black;
  }
  .about-portion {
    display: flex;
    padding: 0 50px;
    min-height: 100vh;
    gap: 20px;
    margin-top: -5px;
  
  }
  
  .about-half {
    flex: 0 0 50%;
    
  }
  
  .about-container {
    margin: auto;
  }
  
  .header-horizontal {
    display: flex;
    margin: auto;
  }
  
  .about-container .dash {
    border-top: 2px solid #0096B1;
    width: 50px;
    margin: auto 10px auto 0;
  }
  
  .about-container h1 {
    font-size: 2.5rem;
    font-weight: 100;
  }
  
  .about-container .heading-bold {
    font-weight: 600;
  }
  
  .about-container button {
    padding: 10px 25px;
    border: none;
    color: #000;
    font-weight: 600;
    border-radius: 5px;
  }
  
  .details {
    padding: 0 0 0 60px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: justify;
  }
  
  .details button {
    width: fit-content;
    font-family: inherit;
    font-size: inherit;
  }
  
  .about-img {
    margin: auto;
  }
  
  .about-img img {
    width: 50%;
    filter: drop-shadow(70px -70px 0 rgba(0,0,0,0.6));
  }
  
  @media only screen and (max-width: 768px) {
    .about-portion {
      flex-direction: column-reverse;
    }
  
    .about-img {
      padding: 120px 0 0 0;
    }
  
    .about-img img {
      width: 90%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .about-img img {
      filter: drop-shadow(40px -40px 0 #0096B1);
    }
    .details {
      padding: 0 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  /* blog */
  .blog_tag{
    font-weight: 500;
    font-family: monospace;
    font-size: 30px;
    
    }
    
    
    
    .blog-portion {
      position: relative;
    }
    
    .blog-content {
      position: absolute;
      text-align: justify;
      background-color: rgba(255, 255, 255, 0.9);
      width: 35%;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      padding: 10px 50px 10px 50px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 10;
    }
    
    .blog_btn {
      margin-top: 24px;
      border: none;
      padding: 12px 24px;
      color: #000;
      text-decoration: none;
      width: -moz-fit-content;
      width: fit-content;
      background-color: #79ffe2;
      border-radius: 5px;
      font-weight: 600;
    }
    .blog_btn:hover{
      background-color:#0089B1;
      color: #fff;
    }
    
    .image-container {
      object-fit: cover;
    }
    
    .image-container img {
      width: 50%;
      height: max-content;
    }

    .blog_card_container {
      /* width: 100%; */
      display: flex;
      flex-wrap: wrap;
    }

    /* Blog Details Styling */

    .main-img {
      height: 50%;
      margin-bottom: 20px;
    }
    
    .image_blog {
      width: 100%; 
      height: 100%; 
      object-fit: contain; 
    }

    .multiple-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      margin: 20px 0;
    }

    .multiple-img img {
      width: 400px;
    }

  @media only screen and (max-width:1024px) {
    .blog-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      background-color: rgba(245, 222, 179, 0.8);
      text-align: center;
    }
  }
  
  @media only screen and (max-width:786px) {
    .flex_card_portion{
      display: grid;
      grid-template-columns: 1fr;
    }
    .introduction_section {
      padding: 20px;
    }
    .heading_intro {
      font-size: 40px;
    }
    .para_intro {
      width: 100%;
      font-size: 20px;
    }
    .content {
      width: 80%;
      margin: auto;
      top: -80px !important;
    }
    .text-box {
      padding: 12px;
      top: -410px;
    }
    .Empowering_portion {
      grid-template-columns: 1fr;
    }
    .form_portion {
      flex-direction: column;
    }
    .contact_form {
      align-self: center;
      width: 100%;
    }
    .card {
      width: 100%;
    }
    .about_heading {
      font-size: 30px;
    }
    .careers-info {
      padding: 25px 25px 50px 25px !important;
    }
    .border-boxing { 
      width: 90%;
    
  }
  .border-boxing h2{
    font-size: 20px;
  }

  .image-container {
    height: 100vh;
  }

.image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(0.8);
}
.blog-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  background-color: rgba(245, 222, 179, 0.8);
}
}

@media only screen and (max-width:480px) {
  .text-box {
    width: 80%;
  }

  .text-box h1 {
    font-size: 20px;
  }
  .text-box h3 {
    font-size: 15px;
  }
  .content {
    width: 80%;
    margin: auto;
    top: -50px !important;
  }
  .about_heading{
    font-size: 25px;
    font-weight: 600;
  }
  .about_paragraph {
    font-size: 20px
  }
  .text_portion h2 {
    font-size: 20px;
  }

  
  .careers-info {
    width: auto;
  }

  .blog-content {
    /* text-align: center;
    width: 70%; */
    background-color: #ffffffe6;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* left: 30%; */
    padding: 0px 20px 0px;
    position: absolute;
    text-align: center;
    top: 55% !important;
    transform: translate(-50%, -50%);
    width: 90% !important;
    z-index: 10;
  }
  .blog-content .blog-heading, .blog-content .blog-para, .blog-content .blog-content {
    margin: 0;
  }

  .blog-content a {
    margin: auto;
  }

  .text_portion {
    padding: 0;
  }

  .about_paragraph_sub_right, .about_paragraph_sub_left, .about_paragraph_Team, .para_intro {
    font-size: 18px;
    text-align: left;
  }

  .para_intro {
    margin: 0px
  }

  .blog-content {
    top: 55% !important;
    font-size: 14px;
  }

  .blog_btn {
    margin: 0;
  }

  .sub_head, .blog_tag, .career-subheading {
    font-size: 24px;
    font-weight: 400;
  }
  
  .contact_page {
    padding: 20px;
  }

  .cardcontact {
    width: 250px;
  }
  
}



